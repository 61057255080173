import { EPostIdCode, EProductType } from '@/ts/constant'

export function GenerateSlugString(slug: string): string {
  if (!slug) return ''
  slug = slug.toLowerCase()
  slug = slug.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  slug = slug.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  slug = slug.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  slug = slug.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  slug = slug.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  slug = slug.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  slug = slug.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  slug = slug.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  slug = slug.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  slug = slug.trim()
  // Remove punctuations
  slug = slug.replace(
    /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ''
  )
  // Replace spaces with hyphens
  slug = slug.replace(/ +/g, '-')
  return slug
}

export function GenerateSlug(
  title?: string,
  id?: string,
  idCode?: EPostIdCode
) {
  if (!title) return ''
  title = GenerateSlugString(title)
  return title + `-${idCode}${id}`
}

export function GeneratePostSlug(title?: string, post_id?: string) {
  if (!title || !post_id) {
    return ''
  }

  title = title.toLowerCase()
  title = title.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  title = title.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  title = title.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  title = title.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  title = title.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  title = title.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  title = title.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  title = title.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  title = title.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư

  title = title.trim()
  // Remove punctuations
  title = title.replace(
    /!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ''
  )
  // Replace spaces with hyphens
  title = title.replace(/ +/g, '-')
  return title + `-pid${post_id}`
}

export function ParseSlug(idCode: EPostIdCode | EProductType, slug?: string) {
  const regexString = `(-${idCode})(\\d+)`
  const regex = new RegExp(regexString, 'i')
  if (slug) {
    if (slug.match(regex)) {
      const pid = slug.match(regex)![2]
      if (pid) {
        return pid
      } else {
        return ''
      }
    }
  }
}

export function ParsePostSlug(slug: string) {
  const regex = /(-pid)(\d+)/i
  if (slug) {
    if (slug.match(regex)) {
      const pid = slug.match(regex)![2]
      if (pid) {
        return pid
      } else {
        return ''
      }
    }
  }
}
