//COMPONENTS
import { PAGE_TYPE } from '@/ts/constant'
import Layout from '../layouts/default'
import {
  Collection,
  Get_Page_With_FilterQuery,
  Pages,
  Pages_Sections,
} from '@/ts/graphqlschema'
import { DOMAIN } from '@/data/metadata'
import Head from 'next/head'
import { formatPagesMetaData } from '../utils'
import { ISectionGallery } from '@/ts/sections'
import { GetStaticProps } from 'next'
import { clientSecret } from '@/graphql/apollo-client-secret'
import { GET_PAGE_WITH_FILTER } from '@/graphql/queries/page/get_page_by_slug'
import fetchExtraData from '@/utils/fetch-extra-data'
import GrapeParser, { HTMLBuilderComponent } from '@/components/GrapeParser'
import React from 'react'
import prepareHTMLBuilderData from '@/utils/prepare-html-builder-data'

type HTMLBuilderContent = {
  component: HTMLBuilderComponent | null
  css: string | null
  globalData: Record<string, any>
}

type HomePageProps = {
  pageData: Pages
  content: HTMLBuilderContent | null
  aboveContent: HTMLBuilderContent | null
  belowContent: HTMLBuilderContent | null
  extra?: any
  section_gallery: ISectionGallery | null
  collections: Collection[]
  section_list_products: Array<Pages_Sections>
}

export const getStaticProps: GetStaticProps = async () => {
  const queryParams = {
    page_filter: {
      page_type: {
        _eq: PAGE_TYPE.HomePage,
      },
    },
  }

  const { data } = await clientSecret.query<Get_Page_With_FilterQuery>({
    query: GET_PAGE_WITH_FILTER,
    variables: queryParams,
    fetchPolicy: 'network-only',
  })
  const pageData = data.pages[0]

  const content = await prepareHTMLBuilderData(pageData?.content)
  const aboveContent = await prepareHTMLBuilderData(
    pageData?.above_content?.content
  )
  const belowContent = await prepareHTMLBuilderData(
    pageData?.below_content?.content
  )

  const extra = await fetchExtraData(pageData)

  return {
    props: {
      pageData: {
        ...pageData,
        content: null,
        above_content: null,
        below_content: null,
      },
      content,
      aboveContent,
      belowContent,
      extra,
    },
    revalidate: 15,
  }
}

const HomePage: (props: HomePageProps) => JSX.Element = (
  props: HomePageProps
) => {
  const { pageData, content, aboveContent, belowContent, extra } = props
  const canonicalUrl = `${DOMAIN}`

  return (
    <Layout
      shouldRenderHighlight
      shouldRenderContacts
      metadata={formatPagesMetaData({ ...pageData, canonicalUrl })}
    >
      {/*<FixedHeader />*/}
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>

      {/*Above Content*/}
      <div>
        {aboveContent?.component && (
          <GrapeParser
            component={aboveContent.component}
            globalData={aboveContent.globalData}
          />
        )}
        {aboveContent?.css && (
          <style dangerouslySetInnerHTML={{ __html: aboveContent.css }} />
        )}
      </div>

      <div>
        {content?.component && (
          <GrapeParser
            component={content.component}
            currentItem={pageData}
            globalData={content.globalData}
            extra={extra}
          />
        )}
        {content?.css && (
          <style dangerouslySetInnerHTML={{ __html: content.css }} />
        )}
      </div>

      {/*Below Content*/}
      <div>
        {belowContent?.component && (
          <GrapeParser
            component={belowContent.component}
            globalData={belowContent.globalData}
          />
        )}
        {belowContent?.css && (
          <style dangerouslySetInnerHTML={{ __html: belowContent.css }} />
        )}
      </div>
    </Layout>
  )
}

export default HomePage
